export class IconSvgs{
    public readonly windIconSvg: string ="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-wind\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M5 8h8.5a2.5 2.5 0 1 0 -2.34 -3.24\"></path>\n" +
        "   <path d=\"M3 12h15.5a2.5 2.5 0 1 1 -2.34 3.24\"></path>\n" +
        "   <path d=\"M4 16h5.5a2.5 2.5 0 1 1 -2.34 3.24\"></path>\n" +
        "</svg>";

    public readonly powerPlantIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-building-factory-2\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M3 21h18\"></path>\n" +
        "   <path d=\"M5 21v-12l5 4v-4l5 4h4\"></path>\n" +
        "   <path d=\"M19 21v-8l-1.436 -9.574a.5 .5 0 0 0 -.495 -.426h-1.145a.5 .5 0 0 0 -.494 .418l-1.43 8.582\"></path>\n" +
        "   <path d=\"M9 17h1\"></path>\n" +
        "   <path d=\"M14 17h1\"></path>\n" +
        "</svg>";

    public readonly turbineIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-building-wind-turbine\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M12 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0\"></path>\n" +
        "   <path d=\"M10 11v-2.573c0 -.18 .013 -.358 .04 -.536l.716 -4.828c.064 -.597 .597 -1.063 1.244 -1.063s1.18 .466 1.244 1.063l.716 4.828c.027 .178 .04 .357 .04 .536v2.573\"></path>\n" +
        "   <path d=\"M13.01 9.28l2.235 1.276c.156 .09 .305 .19 .446 .3l3.836 2.911c.487 .352 .624 1.04 .3 1.596c-.325 .556 -1 .782 -1.548 .541l-4.555 -1.68a3.624 3.624 0 0 1 -.486 -.231l-2.235 -1.277\"></path>\n" +
        "   <path d=\"M13 12.716l-2.236 1.277a3.624 3.624 0 0 1 -.485 .23l-4.555 1.681c-.551 .241 -1.223 .015 -1.548 -.54c-.324 -.557 -.187 -1.245 .3 -1.597l3.836 -2.91a3.41 3.41 0 0 1 .446 -.3l2.235 -1.277\"></path>\n" +
        "   <path d=\"M7 21h10\"></path>\n" +
        "   <path d=\"M10 21l1 -7\"></path>\n" +
        "   <path d=\"M13 14l1 7\"></path>\n" +
        "</svg>";

    public readonly componentsIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-components\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M3 12l3 3l3 -3l-3 -3z\"></path>\n" +
        "   <path d=\"M15 12l3 3l3 -3l-3 -3z\"></path>\n" +
        "   <path d=\"M9 6l3 3l3 -3l-3 -3z\"></path>\n" +
        "   <path d=\"M9 18l3 3l3 -3l-3 -3z\"></path>\n" +
        "</svg>";

    public readonly baseComponentsIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-brand-pagekit\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M12.077 20h-5.077v-16h11v14h-5.077\"></path>\n" +
        "</svg>";

    public readonly sectionsIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-section\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M20 20h.01\"></path>\n" +
        "   <path d=\"M4 20h.01\"></path>\n" +
        "   <path d=\"M8 20h.01\"></path>\n" +
        "   <path d=\"M12 20h.01\"></path>\n" +
        "   <path d=\"M16 20h.01\"></path>\n" +
        "   <path d=\"M20 4h.01\"></path>\n" +
        "   <path d=\"M4 4h.01\"></path>\n" +
        "   <path d=\"M8 4h.01\"></path>\n" +
        "   <path d=\"M12 4h.01\"></path>\n" +
        "   <path d=\"M16 4l0 .01\"></path>\n" +
        "   <path d=\"M4 8m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z\"></path>\n" +
        "</svg>";

    public readonly modelsIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-box-unit-model\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M8 8h8v8h-8z\"></path>\n" +
        "   <path d=\"M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z\"></path>\n" +
        "   <path d=\"M16 16l3.3 3.3\"></path>\n" +
        "   <path d=\"M16 8l3.3 -3.3\"></path>\n" +
        "   <path d=\"M8 8l-3.3 -3.3\"></path>\n" +
        "   <path d=\"M8 16l-3.3 3.3\"></path>\n" +
        "</svg>";

    public readonly dataSettingsIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-database-cog\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3\"></path>\n" +
        "   <path d=\"M4 6v6c0 1.657 3.582 3 8 3c.21 0 .42 -.003 .626 -.01\"></path>\n" +
        "   <path d=\"M20 11.5v-5.5\"></path>\n" +
        "   <path d=\"M4 12v6c0 1.657 3.582 3 8 3\"></path>\n" +
        "   <path d=\"M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0\"></path>\n" +
        "   <path d=\"M19.001 15.5v1.5\"></path>\n" +
        "   <path d=\"M19.001 21v1.5\"></path>\n" +
        "   <path d=\"M22.032 17.25l-1.299 .75\"></path>\n" +
        "   <path d=\"M17.27 20l-1.3 .75\"></path>\n" +
        "   <path d=\"M15.97 17.25l1.3 .75\"></path>\n" +
        "   <path d=\"M20.733 20l1.3 .75\"></path>\n" +
        "</svg>";

    public readonly geographyIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-world-pin\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M20.972 11.291a9 9 0 1 0 -8.322 9.686\"></path>\n" +
        "   <path d=\"M3.6 9h16.8\"></path>\n" +
        "   <path d=\"M3.6 15h8.9\"></path>\n" +
        "   <path d=\"M11.5 3a17 17 0 0 0 0 18\"></path>\n" +
        "   <path d=\"M12.5 3a16.986 16.986 0 0 1 2.578 9.018\"></path>\n" +
        "   <path d=\"M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z\"></path>\n" +
        "   <path d=\"M19 18v.01\"></path>\n" +
        "</svg>";

    public readonly regionIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-world-bolt\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M20.985 12.52a9 9 0 1 0 -7.52 8.36\"></path>\n" +
        "   <path d=\"M3.6 9h16.8\"></path>\n" +
        "   <path d=\"M3.6 15h10.9\"></path>\n" +
        "   <path d=\"M11.5 3a17 17 0 0 0 0 18\"></path>\n" +
        "   <path d=\"M12.5 3c2.313 3.706 3.07 7.856 2.27 12\"></path>\n" +
        "   <path d=\"M19 16l-2 3h4l-2 3\"></path>\n" +
        "</svg>";

    public readonly countriesIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-building-cottage\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M3 21l18 0\"></path>\n" +
        "   <path d=\"M4 21v-11l2.5 -4.5l5.5 -2.5l5.5 2.5l2.5 4.5v11\"></path>\n" +
        "   <path d=\"M12 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0\"></path>\n" +
        "   <path d=\"M9 21v-5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v5\"></path>\n" +
        "</svg>";
    public readonly companiesIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-building-warehouse\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M3 21v-13l9 -4l9 4v13\"></path>\n" +
        "   <path d=\"M13 13h4v8h-10v-6h6\"></path>\n" +
        "   <path d=\"M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3\"></path>\n" +
        "</svg>";
    public readonly ownersIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-building-skyscraper\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M3 21l18 0\"></path>\n" +
        "   <path d=\"M5 21v-14l8 -4v18\"></path>\n" +
        "   <path d=\"M19 21v-10l-6 -4\"></path>\n" +
        "   <path d=\"M9 9l0 .01\"></path>\n" +
        "   <path d=\"M9 12l0 .01\"></path>\n" +
        "   <path d=\"M9 15l0 .01\"></path>\n" +
        "   <path d=\"M9 18l0 .01\"></path>\n" +
        "</svg>";
    public readonly manufacturersIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-camper\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M5 18a2 2 0 1 0 4 0a2 2 0 0 0 -4 0\"></path>\n" +
        "   <path d=\"M15 18a2 2 0 1 0 4 0a2 2 0 0 0 -4 0\"></path>\n" +
        "   <path d=\"M5 18h-1a1 1 0 0 1 -1 -1v-11a2 2 0 0 1 2 -2h12a4 4 0 0 1 4 4h-18\"></path>\n" +
        "   <path d=\"M9 18h6\"></path>\n" +
        "   <path d=\"M19 18h1a1 1 0 0 0 1 -1v-4l-3 -5\"></path>\n" +
        "   <path d=\"M21 13h-7\"></path>\n" +
        "   <path d=\"M14 8v10\"></path>\n" +
        "</svg>";
    public readonly administrationIconSvg="<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"icon icon-tabler icon-tabler-adjustments-horizontal\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n" +
        "   <path stroke=\"none\" d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n" +
        "   <path d=\"M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0\"></path>\n" +
        "   <path d=\"M4 6l8 0\"></path>\n" +
        "   <path d=\"M16 6l4 0\"></path>\n" +
        "   <path d=\"M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0\"></path>\n" +
        "   <path d=\"M4 12l2 0\"></path>\n" +
        "   <path d=\"M10 12l10 0\"></path>\n" +
        "   <path d=\"M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0\"></path>\n" +
        "   <path d=\"M4 18l11 0\"></path>\n" +
        "   <path d=\"M19 18l1 0\"></path>\n" +
        "</svg>";


}
