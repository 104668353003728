<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <div
            id="kt_aside"
            class="aside dashboard-nav-mini"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_mobile_toggle"
            style="{{this.isFullScreen ? 'display: none' : 'display: block'}};"
        >
            <div class="stack">
                <default-brand></default-brand>
                <div class="aside-menu flex-column-fluid frame">
                    <div
                        id="kt_aside_menu_wrapper"
                        style="height: 100%;"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                        data-kt-scroll-wrappers="#kt_aside_menu"
                        data-kt-scroll-offset="0"
                        class="hover-scroll-overlay-y"
                    >
                        <side-bar-menu></side-bar-menu>

                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper"
             style="{{this.isFullScreen ? 'padding-left: 0px !important' : 'padding-left: 88px !important'}}">
            <!--buraya notification modalı ekleyeceğim-->
<!--            <app-status-notification-drawer></app-status-notification-drawer>-->
            <div style="flex: 1">
                <router-outlet></router-outlet>
            </div>
            <!--<footer-bar></footer-bar>-->
        </div>
    </div>
</div>
