import { Injectable } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

import * as moment from 'moment'

@Injectable()
export class DateTimeService {
    constructor(private _appLocalizationService: AppLocalizationService) { }

    createDateRangePickerOptions(): any {
        let options = {
            locale: {
                format: 'L',
                applyLabel: this._appLocalizationService.l('Apply'),
                cancelLabel: this._appLocalizationService.l('Cancel'),
                customRangeLabel: this._appLocalizationService.l('CustomRange'),
            },
            min: this.fromISODateString('2015-05-01'),
            minDate: this.fromISODateString('2015-05-01'),
            max: this.getDate(),
            maxDate: this.getDate(),
            opens: 'left',
            ranges: {},
        };

        options.ranges[this._appLocalizationService.l('Today')] = [this.getStartOfDay(), this.getEndOfDay()];
        options.ranges[this._appLocalizationService.l('Yesterday')] = [
            this.minusDays(this.getStartOfDay(), 1),
            this.minusDays(this.getEndOfDay(), 1),
        ];
        options.ranges[this._appLocalizationService.l('Last7Days')] = [
            this.minusDays(this.getStartOfDay(), 6),
            this.getEndOfDay(),
        ];
        options.ranges[this._appLocalizationService.l('Last30Days')] = [
            this.minusDays(this.getStartOfDay(), 29),
            this.getEndOfDay(),
        ];
        options.ranges[this._appLocalizationService.l('ThisMonth')] = [
            this.getDate().startOf('month'),
            this.getDate().endOf('month'),
        ];
        options.ranges[this._appLocalizationService.l('LastMonth')] = [
            this.getDate().startOf('month').add({ months: -1 }),
            this.getDate().endOf('month').add({ months: -1 }),
        ];

        return options;
    }

    getDate(): moment.Moment {
        if (abp.clock.provider.supportsMultipleTimezone) {
            const moment = require('moment-timezone');
            return moment().tz(abp.timing.timeZoneInfo.iana.timeZoneId);
        } else {
            return moment().local();
        }
    }

    getUTCDate(): moment.Moment {
        return moment().utc();
    }

    getYear(): number {
        return this.getDate().year();
    }

    getStartOfDay(): moment.Moment {
        return moment(this.getDate().startOf('day'));
    }

    getStartOfWeek(): moment.Moment {
        return this.getDate().startOf('week');
    }

    getStartOfDayForDate(date: moment.Moment | Date): moment.Moment {
        if (!date) {
            return date as moment.Moment;
        }

        if (date instanceof Date) {
            return this.getStartOfDayForDate(this.fromJSDate(date));
        }

        return date.startOf('day');
    }

    getStartOfDayMinusDays(daysFromNow: number): moment.Moment {
        let date = this.getDate();
        let newDate = this.minusDays(date, daysFromNow);
        return this.getStartOfDayForDate(newDate);
    }

    getEndOfDay(): moment.Moment {
        return this.getDate().endOf('day');
    }

    getEndOfDayForDate(date: moment.Moment | Date): moment.Moment {
        if (!date) {
            return date as moment.Moment;
        }

        if (date instanceof Date) {
            return this.getEndOfDayForDate(this.fromJSDate(date).toDate());
        }

        return date.endOf('day');
    }

    getEndOfDayPlusDays(daysFromNow: number): moment.Moment {
        let date = this.getDate();
        let newDate = this.plusDays(date, daysFromNow);
        return this.getEndOfDayForDate(newDate);
    }

    getEndOfDayMinusDays(daysFromNow: number): moment.Moment {
        let date = this.getDate();
        let newDate = this.minusDays(date, daysFromNow);
        return this.getEndOfDayForDate(newDate);
    }

    plusDays(date: moment.Moment | Date, dayCount: number): moment.Moment {
        if (date instanceof Date) {
            return this.plusDays(this.fromJSDate(date), dayCount);
        }

        return date.add({ days: dayCount });
    }

    plusSeconds(date: moment.Moment, seconds: number) {
        if (!date) {
            return date;
        }

        if (date instanceof Date) {
            return this.plusSeconds(this.fromJSDate(date), seconds);
        }

        return date.add({ seconds: seconds });
    }

    minusDays(date: moment.Moment, dayCount: number): moment.Moment {
        return date.add(dayCount * -1, 'days');
    }

    fromISODateString(date: string): moment.Moment {
        return moment(date);
    }

    formatISODateString(dateText: string, format: string): string {
        let date = this.fromISODateString(dateText);
        return date.format(format);
    }

    formatJSDate(jsDate: Date, format: string): string {
        let date = moment(jsDate);
        return date.format(format);
    }
    formatDate(date: moment.Moment | Date, format: string): string {
        if (date instanceof Date) {
            return this.formatDate(this.fromJSDate(date), format);
        }

        if (moment.isMoment(date)) {
            return date.format(format);
        }
    }

    getDiffInSeconds(maxDate: moment.Moment | Date, minDate: moment.Moment | Date) {
        if (maxDate instanceof Date && minDate instanceof Date) {
            return this.getDiffInSeconds(this.fromJSDate(maxDate), this.fromJSDate(minDate));
        }

        return (maxDate as moment.Moment).diff(minDate as moment.Moment, 'seconds');
    }

    createJSDate(year: number, month: number, day: number): Date {
        return this.createDate(year, month, day).toDate();
    }

    createDate(year: number, month: number, day: number): moment.Moment {
        if (abp.clock.provider.supportsMultipleTimezone) {
            return moment({ year: year, month: month + 1, day: day}).utc();
        } else {
            return moment({ year: year, month: month + 1, day: day});
        }
    }

    createUtcDate(year: number, month: number, day: number): moment.Moment {
        return moment({ year: year, month: month + 1, day: day}).utc();
    }

    toUtcDate(date: moment.Moment | Date): moment.Moment {
        if (date instanceof Date) {
            return this.createUtcDate(date.getFullYear(), date.getMonth(), date.getDate());
        }

        return date.utc();
    }

    fromJSDate(date: Date): moment.Moment {
        return moment(moment(date));
    }

    fromNow(date: moment.Moment | Date): string {
        if (date instanceof Date) {
            return this.fromNow(this.fromJSDate(date).toDate());
        }

        return date.fromNow();
    }

    getTimezoneOffset(ianaTimezoneId: string): number {
        const hourAndMinuteOffset = moment().zone(ianaTimezoneId).format('ZZ');
        const multiplier = hourAndMinuteOffset[0] === '-' ? -1 : +1;
        const hourParts = hourAndMinuteOffset.replace('-','').replace('+','').split(':');
        const hourOffset = hourParts[0];
        const minuteOffset = hourParts[1];
        return multiplier * (parseInt(hourOffset) * 60 + parseInt(minuteOffset));
    }

    // only changes timezone of given date without changing the date itself
    changeTimeZone(date: Date, ianaTimezoneId: string): Date{
        const utcDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
        const currentTimezoneString = moment().utcOffset(ianaTimezoneId).format('ZZ');
        const dateStringWithoutTimezome = utcDateString.substring(0,utcDateString.length-1) + currentTimezoneString;
        return moment(dateStringWithoutTimezome).toDate();
    }

    changeDateTimeZone(date: moment.Moment, ianaTimezoneId: string): moment.Moment {
        let jsDate = date.toDate();
        let utcDate = this.changeTimeZone(jsDate, ianaTimezoneId);
        return moment(utcDate);
    }
}
