import {AssetDetail} from '@app/main/map/map.model';
import { CreateOrEditAssetDto } from '@shared/service-proxies/service-proxies';

export class MapModel {
    isAssetViewActive?: boolean = false;
    isCreated?: boolean = false;
    location?: MapLocationModel = new MapLocationModel();
    assetDetail?: CreateOrEditAssetDto;
}

export class MapLocationModel {
    longitude: number = 0;
    latitude: number = 0;
}

