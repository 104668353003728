
import {PermissionCheckerService} from 'abp-ng2-module';
import {AppSessionService} from '@shared/common/session/app-session.service';
import {Injectable} from '@angular/core';
import {AppMenu} from './app-menu';
import {AppMenuItem} from './app-menu-item';
import {IconSvgs} from '@app/shared/layout/nav/icon-svg';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {
    }

    private readonly iconSvgs: IconSvgs = new IconSvgs();
    private readonly starIconSvg: string =
        '<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path opacity="0.32" fill-rule="evenodd" clip-rule="evenodd" d="M0.5 11C0.5 16.799 5.20101 21.5 11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 5.20101 16.799 0.5 11 0.5C5.20101 0.5 0.5 5.20101 0.5 11ZM11.9491 4.80725C11.5501 4.06425 10.4499 4.06425 10.051 4.80725L8.54862 7.60535L5.36352 8.2112C4.54932 8.36605 4.22022 9.319 4.77507 9.9151L7.03362 12.3415L6.61877 15.5888C6.51432 16.4065 7.39427 17.0073 8.15727 16.6394L11 15.2687L13.8428 16.6394C14.6058 17.0073 15.4857 16.4065 15.3813 15.5888L14.9664 12.3415L17.225 9.9151C17.7798 9.319 17.4507 8.36605 16.6365 8.2112L13.4514 7.60535L11.9491 4.80725Z" fill="#637381"/> </svg>';


    menuIconStyle = "style='width: 22px;height: 22px'";

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Map',
                'Pages.Map',
                "<img src='/assets/common/images/menu/map.svg' [style]='menuIconStyle' />",
                '/app/main/map',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                "<img src='/assets/common/images/menu/map-active.svg' [style]='menuIconStyle' />",
                undefined,
                false
            ),
            new AppMenuItem('Scenarios', 'Pages.Scenarios', this.starIconSvg, '/app/main/scenarios'),
            new AppMenuItem('Prices', 'Pages.Prices', this.starIconSvg, '/app/main/prices'),
            new AppMenuItem('Assets', 'Pages.Assets', this.starIconSvg, '/app/main/assets'),
            new AppMenuItem('Companies', 'Pages.Companies', this.starIconSvg, '/app/main/companies'),
            new AppMenuItem('Countries', 'Pages.Countries', this.starIconSvg, '/app/main/countries'),
            new AppMenuItem('Tenants', 'Pages.Tenants', this.starIconSvg, '/app/admin/tenants'),
            new AppMenuItem(
                'Administration',
                '',
                this.iconSvgs.administrationIconSvg,
                '',
                [],
                [
                    new AppMenuItem(
                        "Roles",
                        "Pages.Administration.Roles",
                        this.starIconSvg,
                        "/app/admin/roles",
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true
                    ),
                    new AppMenuItem(
                        "Users",
                        "Pages.Administration.Users",
                        this.starIconSvg,
                        "/app/admin/users",
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true
                    ),
                    // new AppMenuItem(
                    //     'Languages',
                    //     'Pages.Administration.Languages',
                    //     '',
                    //     '/app/admin/languages',
                    //     ['/app/admin/languages/{name}/texts'],
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     true
                    //
                    // ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        '',
                        '/app/admin/auditLogs',
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true
                    ),
                    // new AppMenuItem(
                    //     'Maintenance',
                    //     'Pages.Administration.Host.Maintenance',
                    //     '',
                    //     '/app/admin/maintenance',
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     true
                    // ),
                    // new AppMenuItem(
                    //     'Subscription',
                    //     'Pages.Administration.Tenant.SubscriptionManagement',
                    //     '',
                    //     '/app/admin/subscription-management',
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     true
                    // ),
                    // new AppMenuItem(
                    //     'VisualSettings',
                    //     'Pages.Administration.UiCustomization',
                    //     '',
                    //     '/app/admin/ui-customization',
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     true
                    // ),
                    // new AppMenuItem(
                    //     'WebhookSubscriptions',
                    //     'Pages.Administration.WebhookSubscription',
                    //     '',
                    //     '/app/admin/webhook-subscriptions',
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     true
                    // ),
                    // new AppMenuItem(
                    //     'DynamicProperties',
                    //     'Pages.Administration.DynamicProperties',
                    //     '',
                    //     '/app/admin/dynamic-property',
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     true
                    // ),
                    // new AppMenuItem(
                    //     'Notifications',
                    //     '',
                    //     '',
                    //     '',
                    //     [],
                    //     [
                    //         new AppMenuItem(
                    //             'Inbox',
                    //             '',
                    //             '',
                    //             '/app/notifications',
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             true
                    //         ),
                    //         new AppMenuItem(
                    //             'MassNotifications',
                    //             'Pages.Administration.MassNotification',
                    //             '',
                    //             '/app/admin/mass-notifications',
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             undefined,
                    //             true
                    //         )
                    //     ]
                    // ),
                    // new AppMenuItem(
                    //     'Settings',
                    //     'Pages.Administration.Host.Settings',
                    //     '',
                    //     '/app/admin/hostSettings',
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     true
                    // ),
                    // new AppMenuItem(
                    //     'Settings',
                    //     'Pages.Administration.Tenant.Settings',
                    //     '',
                    //     '/app/admin/tenantSettings',
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     undefined,
                    //     true
                    // )
                ],
                undefined,
                undefined,
                undefined,
                undefined,
                true
            )
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }

    getSubMenu(): AppMenu {
        return new AppMenu("MainMenu", "MainMenu", []);
    }
}
