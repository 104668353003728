import { Pipe, PipeTransform } from '@angular/core';
import * as moment from '@node_modules/moment';


@Pipe({ name: 'momentFormat' })
export class MomentFormatPipe implements PipeTransform {
    transform(value: moment.Moment, format: string) {
        if (!value) {
            return '';
        }

        return value.format(format);
    }
}
