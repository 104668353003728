import {Component, ViewChild, Injector, Output, EventEmitter, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {finalize} from 'rxjs/operators';
import {
    PricesServiceProxy,
    CreateOrEditPriceDto,
    PriceCountryLookupTableDto,
    MarketPriceDataDto,
    DateValueDataDto,
    CreateOrEditAssetDto, CapacityFactorDto, ICreateOrEditAssetDto, ICreateOrEditPriceDto, MarketPriceDto, DailyMonthlyMeanSummaryDto
} from '@shared/service-proxies/service-proxies';
import {AppComponentBase} from '@shared/common/app-component-base';
import {AutoCompleteCompleteEvent} from 'primeng/autocomplete';
import more from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
import HC_lollipop from 'highcharts/modules/lollipop';
import HC_dumbbell from 'highcharts/modules/dumbbell';
import {Observable} from 'rxjs';
import {SeriesOptionsType} from 'highcharts';
import { FileDownloadService } from '@shared/utils/file-download.service';

HC_stock(Highcharts);
more(Highcharts);
HC_dumbbell(Highcharts);
HC_lollipop(Highcharts);


@Component({
    selector: 'meanChart',
    templateUrl: './mean-chart.component.html',
    styleUrls: ["./mean-chart.component.css"],

})
export class MeanChartComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input('data') data: DailyMonthlyMeanSummaryDto;
    @Input('title') title: string = 'Values';
    totalMean: number;

    Highcharts: typeof Highcharts = Highcharts;
    hourlyChartOptions: Highcharts.Options = {};
    monthlyChartOptions: Highcharts.Options = {};
    updateHourlyChart = false;
    updateMonthlyChart = false;

    constructor(
        injector: Injector,
        private readonly _fileDownloadService: FileDownloadService
    ) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.data){
            this.updateDailyMeanChart(this.mapDailyMean(this.data.summaries));
            this.updateMonthlyMeanChart(this.mapMonthlyMean(this.data.summaries));
            this.totalMean = this.data?.mean;
        }
    }

    ngOnInit(): void {
    }
    
    updateDailyMeanChart(data) {
        this.hourlyChartOptions = {
            title: {
                text: 'Daily Mean'
            },
            series: [
                {
                    name: 'Value',
                    turboThreshold: 0,
                    data: data,
                    type: 'line',
                },
            ],

            legend: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
            },
        };
        this.updateHourlyChart = true;
    }

    updateMonthlyMeanChart(data) {
        this.monthlyChartOptions = {
            title: {
                text: 'Monthly Mean'
            },
            chart: {
                type: 'lollipop'
            },
            series: [
                {
                    name: 'Value',
                    turboThreshold: 0,
                    data: data
                } as SeriesOptionsType
            ],
            legend: {
                enabled: false
            },
            xAxis: {
                type: 'category'
            },
        };
        this.updateMonthlyChart = true;
    }

    exportCapacityFactorsToExcel(): void {
        this._fileDownloadService.saveObjectAsCsv(this.data.values)
    }

    private mapDailyMean(summaries: { [key: string]: DateValueDataDto[]; }): { x: number, y: number }[] {
        return summaries['daily_mean'].map(cf => ({x: Date.parse(cf.date), y: cf.value}));
    }

    private mapMonthlyMean(summaries: { [key: string]: DateValueDataDto[]; }): { name: string, y: number }[] {
        return summaries['monthly_mean'].map(cf => ({
            name: new Date(Date.parse(cf.date)).toLocaleString('default', {month: 'short'}),
            y: cf.value
        }));
    }
}
