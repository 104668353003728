import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { FileDto } from '@shared/service-proxies/service-proxies';

@Injectable()
export class FileDownloadService {
    getTempFileUrl(file: FileDto){
        const url =
            AppConsts.remoteServiceBaseUrl +
            '/File/DownloadTempFile?fileType=' +
            file.fileType +
            '&fileToken=' +
            file.fileToken +
            '&fileName=' +
            file.fileName;
        
        return url;
    }

    downloadTempFile(file: FileDto) {
        const url = this.getTempFileUrl(file);
        location.href = url; //TODO: This causes reloading of same page in Firefox
    }

    saveObjectAsCsv(data: any){
        const replacer = (key, value) => (value === null ? '' : value);
        const header = Object.keys(data[0]);
        const csv = data.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(',')
        );
        csv.unshift(header.map(x=> x.charAt(0).toUpperCase() + x.slice(1)).join(','));
        const csvArray = csv.join('\r\n');
        
        const a = document.createElement('a');
        const blob = new Blob([csvArray], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        
        a.href = url;
        a.download = 'HourlyValues.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
