import { createReducer, on } from "@ngrx/store";
import { MapModel } from "@app/state-management/models/map.model";
import {
    setMapLocation,
    setMapState
} from "@app/state-management/actions/map.action";

// State Type
export interface IMapState {
    data: MapModel;
}

// Initial State
export const initialState: IMapState = {
    data: new MapModel
};

// Reducer
export const mapReducer = createReducer(initialState,
    on(setMapState, (state, mapModel) => {
        const newState: IMapState = {
            data: {
                isCreated: mapModel.isCreated,
                isAssetViewActive: mapModel.isAssetViewActive,
                assetDetail: mapModel.assetDetail
            }
        };
        return newState;
    }),
    on(setMapLocation, (state, mapModel) => {
        const newState: IMapState = {
            data: {
                location: mapModel.location,
                isAssetViewActive: mapModel.isAssetViewActive,
                assetDetail: mapModel.assetDetail
            }
        };
        return newState;
    })
);
