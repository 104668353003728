<ng-container *ngIf="data">
    <h3>{{title}}</h3>
    <highcharts-chart
        constructorType="stockChart"
        [Highcharts]="Highcharts"
        [options]="hourlyChartOptions"
        [(update)]="updateHourlyChart"
        style="width: 100%; height: 400px; display: block;"
    ></highcharts-chart>
    <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="monthlyChartOptions"
        [(update)]="updateMonthlyChart"
        style="width: 100%; height: 400px; display: block;"
    ></highcharts-chart>
    <div class="mt-5" style="display: flex; align-items: center; justify-content: space-between;width: 100%;">
        <span class="publicsans-bold-charade-14px">Total mean: {{totalMean < 1 ? (totalMean | percent: '1.2-2') : totalMean}}</span>
        <button id="btn_download_asset" type="button" (click)="exportCapacityFactorsToExcel()"
        class="btn btn-secondary"><i class="fa fa-download"></i> {{l("SaveHourlyOutputAsCsv")}}</button>
    </div>
</ng-container>